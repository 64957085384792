/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import ProxySettingsData from '../ProxySettings/ProxySettings'
import {
  CategoriesTitle,
  CategoriesTree,
  PageDesc,
  SettingsCategories,
  SettingsFlexRow,
  SettingsSelection,
  Container,
  ActionArea,
  SearchForm,
  ProgressBlock,
  ActionAreaElements,
  StyledFilterTag,
  ActionAreaFilterTags
} from './styles'
import {
  TreeView,
  IconDownload,
  IconFunnel,
  Button,
  Tooltip,
  ProgressIndicator,
  Search
} from '@veneer/core'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext'
import { ProxySettings } from '../../interfaces/proxyInterface'
import {
  getSettings,
  getSettingsCategories,
  getWarningAlt,
  getEnabledSettingCount
} from '../../utils/api'
import { formattedName } from '../../utils/commonMethods'
import * as T from './type'
import ProxyPageDescription from '../ProxyPageDescription'
import ProxyWarningAlt from '../ProxyWarningAlt'
import FilterSidebar from '../../Shared-Components/FilterSidebar/FilterSidebar'

const ProxieSettingsMainPage = (props: T.FleetProxySettingsProps) => {
  const idAll = 100

  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const authProvider = interfaces?.v2.authProvider || {}
  const events = interfaces?.v1?.events || props?.mockUnitTestProps?.events

  const [categories, setCategories] = useState([])
  const [proxySettings, setProxySettings] = useState<Array<ProxySettings>>([])
  const [selectedCategory, setSelectedCategory] = useState<number>(idAll)
  const [selectedCategoryName, setSelectedCategoryName] = useState<string>(null)
  const [searchProxySettingsItem, setSearchProxySettingsItem] =
    useState<string>('')
  const [searchSettingsItems, setSearchSettingsItems] = useState<string[]>(
    props.searchSettingsItems || []
  )
  const [searchProxySettingsFlag, setSearchProxySettingsFlag] =
    useState<boolean>(false)
  const [searchProxySettingsValue, setSearchProxySettingsValue] =
    useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isCategoriesLoading, setIsCategoriesLoading] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)
  const [hasWarningAlt, setHasWarningAlt] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>()
  const [showSettingsFilterSidebar, setShowSettingsFilterSidebar] =
    useState<boolean>(false)
  const WARNING_STATUS_KEY = 'warningStatus'
  const STATUS_VALUE = 'Status'
  const DATA_COLLECTION_KEY = 'connectivity'
  const DATA_COLLECTION_VALUE = 'Data Collection'
  const retriveSettingsCategories = async () => {
    setIsCategoriesLoading(true)
    try {
      const response = await getSettingsCategories(authProvider, authStack)
      setCategories(response)
      setIsCategoriesLoading(false)
    } catch (err) {
      console.warn('Error while getting Settings Category list', err)
    }
  }
  /* istanbul ignore next */
  const retriveProxySettingsList = async () => {
    try {
      setIsLoading(true)
      const response: ProxySettings[] = await getSettings(
        authProvider,
        authStack
      )
      setTotalCount(response.length)
      setProxySettings(response)
      const warningAlt = await getWarningAlt(response)
      warningAlt?.length != 0 ? setHasWarningAlt(true) : setHasWarningAlt(false)
      setIsLoading(false)
      setHasError(false)
    } catch (e) {
      setIsLoading(false)
      setHasError(true)
    }
  }
  useEffect(() => {
    retriveProxySettingsList()
  }, [])

  useEffect(() => {
    retriveSettingsCategories()
  }, [isLoading])

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', retriveProxySettingsList)
    return () => {
      events.removeEventListener(
        'ecp-banner-reload-call',
        retriveProxySettingsList
      )
    }
  }, [events, retriveProxySettingsList])

  /* istanbul ignore next */
  const key = (subkey: string): string => {
    return t(
      `ecp-fleet-proxy-settings.categories.${subkey}`,
      formattedName(subkey)
    )
  }
  const filterTagKey = (tagKey: string): string => {
    const keyMapping =
      tagKey.toLowerCase() === 'warning'
        ? WARNING_STATUS_KEY
        : DATA_COLLECTION_KEY
    const valueMapping =
      tagKey.toLowerCase() === 'warning' ? STATUS_VALUE : DATA_COLLECTION_VALUE

    return `${t(`ecp-fleet-proxy-settings.${keyMapping}`, valueMapping)}: ${t(
      `ecp-fleet-proxy-settings.${tagKey.toLowerCase()}`,
      tagKey
    )}`
  }

  /* istanbul ignore next */
  const categoryNodes = useMemo(() => {
    if (!categories) {
      return null
    }
    const treeNodes = categories.map((item) => {
      const totalChildren = getEnabledSettingCount(proxySettings, item)
      return {
        id: item,
        label: key(item),
        totalChildren: totalChildren != 0 ? totalChildren : null
      }
    })
    return treeNodes
  }, [categories])

  /* istanbul ignore next */
  const handleProxyFilter = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setIsLoading(true)
    setSearchProxySettingsItem(searchProxySettingsValue)
    setSearchProxySettingsFlag(!searchProxySettingsFlag)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }
  /* istanbul ignore next */
  const handleSettingsPerformFilter = (value1: string, value2: string) => {
    if (value2 === 'push') {
      setSearchSettingsItems([...searchSettingsItems, value1])
    }
    if (value2 === 'pop') {
      setSearchSettingsItems(
        searchSettingsItems.filter((item) => item !== value1)
      )
    }
    if (value2 === 'clearall') {
      setSearchSettingsItems([])
    }
  }
  /* istanbul ignore next */
  const handleChange = async (id) => {
    setIsLoading(true)
    if (id !== idAll) {
      setSelectedCategory(id)
      setSelectedCategoryName(id)
    } else {
      setSelectedCategory(0)
      setSelectedCategoryName('')
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }
  const proxySettingsTableActionArea = () => {
    return (
      <ActionArea data-testid="proxy-settings-component-actionarea">
        <ActionAreaElements>
          <SearchForm data-testid="proxy-settings-list-search-block">
            <form
              onSubmit={handleProxyFilter}
              data-testid="proxy-settings-list-search-form"
            >
              <Search
                data-testid="proxy-settings-search-box"
                placeholder={t(
                  'ecp-fleet-proxy-settings.searchInAll',
                  'Search in All'
                )}
                onChange={(value) => setSearchProxySettingsValue(value)}
                disabled={
                  isLoading || isCategoriesLoading || proxySettings.length === 0
                }
                onClear={() => {
                  setSearchProxySettingsItem('')
                  setSearchProxySettingsFlag(!searchProxySettingsFlag)
                }}
                content={undefined}
                nonce={undefined}
                rel={undefined}
                rev={undefined}
              />
            </form>
            <Button
              appearance="ghost"
              data-testid="proxy-settings-table-filter-button"
              leadingIcon={
                <IconFunnel
                  data-testid="icon-funnel"
                  filled={searchSettingsItems.length > 0 ? true : false}
                />
              }
              onClick={() => setShowSettingsFilterSidebar(true)}
              disabled={
                isLoading || isCategoriesLoading || proxySettings.length === 0
              }
            >
              {t('ecp-fleet-proxy-settings.filter', 'Filter')}
              {searchSettingsItems.length > 0
                ? ` (` + searchSettingsItems.length + `)`
                : ''}
            </Button>
          </SearchForm>

          <Tooltip
            arrow
            content={t(
              'ecp-fleet-proxy-settings.auditLogToolTip',
              'Click on Audit Log to download a JSON file providing information about when and what data collection permissions were changed.'
            )}
            id="tooltip"
            placement="bottom-end"
          >
            <Button
              appearance="secondary"
              leadingIcon={<IconDownload />}
              disabled={
                isLoading || isCategoriesLoading || proxySettings.length === 0
              }
              onClick={() => {
                const jsonContent = JSON.stringify(
                  proxySettings.map(({ name, category, allow }) => ({
                    name,
                    category,
                    allow
                  })),
                  null,
                  2
                )
                const blob = new Blob([jsonContent], {
                  type: 'application/json'
                })
                const url = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = 'data-collection-permissions.json'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                URL.revokeObjectURL(url)
              }}
            >
              {t('ecp-fleet-proxy-settings.auditLog', 'Audit Log')}
            </Button>
          </Tooltip>
        </ActionAreaElements>
        {searchSettingsItems && (
          <ActionAreaFilterTags>
            {searchSettingsItems.map((item: string, index: number) => (
              <StyledFilterTag
                id="proxy-settings-filter-tags"
                key={index}
                label={filterTagKey(item)}
                clearButton
                onClick={() => {
                  searchSettingsItems.splice(index, 1)
                  retriveProxySettingsList()
                }}
              />
            ))}
            {searchSettingsItems.length > 1 && (
              <Button
                id="clear-all-tags"
                appearance="ghost"
                small
                onClick={() => {
                  searchSettingsItems.splice(0, searchSettingsItems.length)
                  retriveProxySettingsList()
                }}
              >
                {t('ecp-fleet-proxy-settings.clearAll', 'Clear All')}
              </Button>
            )}
          </ActionAreaFilterTags>
        )}
      </ActionArea>
    )
  }
  /* istanbul ignore next */
  return (
    <Container>
      {showSettingsFilterSidebar && (
        <FilterSidebar
          data-testid="proxy-settings-filter-side-bar"
          onclose={() => setShowSettingsFilterSidebar(false)}
          performFilter={(value1: string, value2: string) =>
            handleSettingsPerformFilter(value1, value2)
          }
          appliedFilter={searchSettingsItems}
        />
      )}
      <PageDesc data-testid="proxy-settings-page-header">
        {hasWarningAlt && <ProxyWarningAlt />}
        <ProxyPageDescription />
      </PageDesc>
      <SettingsFlexRow data-testid="proxy-settings-div">
        <SettingsCategories data-testid="proxy-settings-categories">
          {(isCategoriesLoading || isLoading) && (
            <ProgressBlock>
              <ProgressIndicator
                appearance="circular"
                behavior="indeterminate"
              />
            </ProgressBlock>
          )}

          {((!isCategoriesLoading && !isLoading) || props.mock) && (
            <>
              <CategoriesTitle data-testid="proxy-settings-category-title">
                {t('ecp-fleet-proxy-settings.category', 'Category')}
              </CategoriesTitle>
              <CategoriesTree data-testid="proxy-settings-category-list">
                <TreeView
                  defaultExpandedNodes={[idAll]}
                  defaultSelectedNodes={[idAll]}
                  nodes={[
                    {
                      id: idAll,
                      label: t('ecp-fleet-proxy-settings.all', 'All'),
                      totalChildren: totalCount != 0 ? totalCount : null,
                      nodes: categoryNodes
                    }
                  ]}
                  selectedNodes={[selectedCategory]}
                  onChange={(e, id) =>
                    totalCount !== 0 && handleChange(id || idAll)
                  }
                />
              </CategoriesTree>
            </>
          )}
        </SettingsCategories>
        <SettingsSelection data-testid="proxy-settings-selection-list">
          <ProxySettingsData
            currentCategory={selectedCategoryName}
            actionArea={proxySettingsTableActionArea()}
            data-testid="proxy-settings-list-component"
            hasError={hasError}
            proxySettings={proxySettings}
            isLoading={isLoading}
            searchProxySettingsItem={searchProxySettingsItem}
            searchSettingsItems={searchSettingsItems}
            retriveProxySettings={retriveProxySettingsList}
          />
        </SettingsSelection>
      </SettingsFlexRow>
    </Container>
  )
}

export default ProxieSettingsMainPage
